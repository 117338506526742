import React from 'react'
import { PageProps, graphql, Link } from 'gatsby'
import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'
import { WorkListQuery } from 'types/graphql-types'

const WorkList: React.FC<PageProps<WorkListQuery>> = ({ data }) => {
  const items = data.works.edges
  return (
    <>
      <SEO title="Works" />
      <Layout>
        <ul>
          {items.map(({ node }) => (
            <li key={node.id} className="pb-12">
              <Link to={`./${node.uid!}`}>
                <h1 className="text-xl">{node.data?.title?.text}</h1>
                <h2>{node.data?.media}</h2>
              </Link>
            </li>
          ))}
        </ul>
      </Layout>
    </>
  )
}

export const query = graphql`
  query WorkList($language: String!) {
    works: allPrismicWorks(
      filter: { lang: { eq: $language } }
      sort: {
        fields: [data___featured, data___launch_date]
        order: [DESC, DESC]
      }
    ) {
      edges {
        node {
          id
          uid
          data {
            title {
              text
            }
            media
          }
        }
      }
    }
  }
`

export default WorkList
